@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
// endbower

@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";

/********** General **********/

html {
	-webkit-font-smoothing: antialiased;
}

body {
	font-family: Montserrat;
	line-height: 1.625;
}

/* Fix for weird tag bug where body class being added causes the layout to be messed up */
body.tag {
	display: inherit;
    padding: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1.625;
    color: inherit;
    text-align: inherit;
    white-space: inherit;
    vertical-align: inherit;
    border-radius: inherit;
}

section {
	margin-bottom: 1.5625rem;
}

p {
	text-rendering: optimizeLegibility;
}

@media (max-width: 767px) {
	.content main {
		padding-left: 0;
		padding-right: 0;
	}
}
@media (min-width: 768px) {
	section {
		margin-bottom: 3.125rem;
	}	
}

/***** General > Headings *****/

h1, h2, h3, h4, h5 , h6 {
	color: #252525;
	font-family: Playfair Display;
	font-weight: 700;
}

.section-title, .entry-title {
	font-size: 2.25rem;
	margin-bottom: 1.5625rem;
	padding-bottom: 1.5rem;
	position: relative;
}

.section-title:after, .entry-title:after {
	content: "";
    position: absolute;
    width: 5rem;
    height: .5rem;
    bottom: 0;
    left: 0;
    border-bottom: .5rem solid #252525;
}

@media (max-width: 767px) {
	header, .entry-content {
		padding: 0 15px;
	}
}

@media (min-width: 768px) {
	.section-title, .entry-title {
	    margin-bottom: 3.125rem;
	}
}

/***** General > Buttons *****/

.btn {
	border-radius: 0;
}

.btn-primary {
	background-color: #252525;
    border-color: #252525;
	text-transform: uppercase;
}

/***** General > Call to Actions *****/

.cta {
	border: 5px solid;
	font-size: 1.25rem;
	margin-top: 3.125rem;
	padding: 1.75rem;
	text-align: center;
}

.cta p, .cta .btn-primary {
	margin-bottom: 1.75rem;
}

.search-form .search-field {
	border-radius: 0;
}

.search-form .search-submit {
    color: #fff;
    background-color: #7c7c7c;
    border-color: transparent;
    text-transform: uppercase;
}

/**********

***** Header/ Navbar *****

**********/

/* Mobile Push Menu, mobile style only - https://tympanus.net/codrops/2013/04/17/slide-and-push-menus/ */

@media (max-width: 767px) {

	/* General styles for all menus */
	.cbp-spmenu {
	    background: #252525;
	    position: fixed;
	}

	.cbp-spmenu h3 {
		color: #fff;
	    font-size: 2.25rem;
	    padding-bottom: 2.5rem;
	    margin: 0;
	    font-weight: 300;
	    margin-bottom: .875rem;
	}

	.cbp-spmenu a {
	    display: block;
	    color: #fff;
	    font-size: 1.1em;
	    font-weight: 300;
	    position: relative;
	}

	.cbp-spmenu a:hover {
	    background: #258ecd;
	}

	.cbp-spmenu a:active {
	    background: #afdefa;
	    color: #47a3da;
	}

	/* Orientation-dependent styles for the content of the menu */
	.cbp-spmenu-vertical {
	    width: 240px;
	    height: 100%;
	    top: 0;
	    z-index: 1000;
	    overflow-y: scroll;
	}

	.cbp-spmenu-vertical li {
		position: relative;
	}

	.cbp-spmenu-vertical li:before, .cbp-spmenu-vertical li:after {
		position: absolute;
	    top: 57.5px;
	    left: 50%;
	    width: 0;
	    height: 0;
	    border: solid transparent;
	    content: "";
	    pointer-events: none;
	}

	.cbp-spmenu-vertical li:before {
	    margin-left: -9px;
	    border-width: 10px;
	    border-top-color: #fff;
	}

	.cbp-spmenu-vertical li:after {
		top: 94%;
	    margin-left: -5px;
	    border-width: 6px;
	    border-top-color: #252525;
	}

	.cbp-spmenu-vertical li a {
	    padding: 1rem 1rem 1rem 0;
	}

	.cbp-spmenu-vertical li a:before, .cbp-spmenu-vertical li a:after {
	    background: #fff;
	    content: "";
	    position: absolute;
	    bottom: 0;
	    left: 0;
	    width: 100%;
	    height: 3px;
	}

	.cbp-spmenu-vertical li a:before {
		background: #7c7c7c;
	}

	.cbp-spmenu-vertical li a:after {
		background: #fff;
	}

	.cbp-spmenu-horizontal {
	    width: 100%;
	    height: 150px;
	    left: 0;
	    z-index: 1000;
	    overflow: hidden;
	}

	.cbp-spmenu-horizontal h3 {
	    height: 100%;
	    width: 20%;
	    float: left;
	}

	.cbp-spmenu-horizontal a {
	    float: left;
	    width: 20%;
	    padding: 0.8em;
	    border-left: 1px solid #258ecd;
	}

	/* Vertical menu that slides from the left or right */
	.cbp-spmenu-left {
	    left: -240px;
	    text-align: center;
	}

	.cbp-spmenu-right {
	    right: -240px;
	}

	.cbp-spmenu-left.menu-open {
	    left: 0px;
	}

	.cbp-spmenu-right.menu-open {
	    right: 0px;
	}

	/* Horizontal menu that slides from the top or bottom */

	.cbp-spmenu-top {
	    top: -150px;
	}

	.cbp-spmenu-bottom {
	    bottom: -150px;
	}

	.cbp-spmenu-top.menu-open {
	    top: 0px;
	}

	.cbp-spmenu-bottom.menu-open {
	    bottom: 0px;
	}

	/* Push classes applied to the body */
	.push-body {
	    overflow-x: hidden;
	    position: relative;
	    left: 0;
	}

	.push-body-toright {
	    left: 240px;
	}

	.push-body-toleft {
	    left: -240px;
	}

	/* Transitions */
	.cbp-spmenu,
	.push-body {
	    -webkit-transition: all 0.3s ease;
	       -moz-transition: all 0.3s ease;
	            transition: all 0.3s ease;
	}

	.toggle-menu {
		border-color: #252525;
	    position: relative;
	    padding: 9px 10px;
	    margin-top: 8px;
	    margin-right: auto;
	    margin-left: auto;
	    margin-bottom: 8px;
	    background-color: transparent;
	    background-image: none;
	    border: 1px solid transparent;
	    border-radius: 4px;
	    display: block;
	}

	.toggle-menu .icon-bar {
		display: block;
	    width: 22px;
	    height: 2px;
	    border-radius: 1px;
	    color: #252525;
	    background-color: #252525;
	}

	.toggle-menu .icon-bar+.icon-bar {
	    margin-top: .25rem;
	}

	.toggle-menu p {
		float: left;
		font-family: 'Playfair Display';
	    margin-left: 2rem;
	    display: inline-block;
	    line-height: .875;
	    margin-bottom: 0;
	}

}

/* https://tympanus.net/codrops/2016/01/06/inspiration-for-line-menu-styles/ */

/* Temporarily adding a media query wrap to prevent it displaying on mobile */

@media (min-width: 768px) {

	.navbar /* .menu */ {
		line-height: 1.15;
	    margin-bottom: 2rem;
	    padding: 0;
		/* this was .menu-primary-navigation before installing SOIL */
	    display: -webkit-flex;
	    display: flex;
	    -webkit-flex-direction: column;
	    flex-direction: column;
	    -webkit-justify-content: center;
	    justify-content: center;
	    -webkit-align-items: center;
	    align-items: center;
	}

	.nav /* .menu__list */ {
		position: relative;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.menu-item /* .menu__item */ {
		display: block;
		margin: 1rem 0;
	}

	.menu-item a /* .menu__link */ {
		font-weight: 700;
	    display: block;
	    color: #252525;
		padding: 1rem;
		cursor: pointer;
		text-align: center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.menu-item a:hover, /* .menu__link:hover */,
	.menu-item a:focus /* .menu__link:focus */ {
		outline: none;
		text-decoration: none;
	}

	/* https://tympanus.net/Development/LineMenuStyles/#juliet */

	/* Juliet Styles */

	.menu--juliet .menu-item {
		position: relative;
		-webkit-transition: color .25s;
		transition: color .25s;
	}

	.menu--juliet .menu-item::before,
	.menu--juliet .menu-item::after,
	.menu--juliet .menu-item.active::before,
	.menu--juliet .menu-item.active::after {
		position: absolute;
		left: 50%;
		width: 0;
		height: 0;
		border: solid transparent;
		content: '';
		pointer-events: none;
	}
	.menu--juliet .menu-item::before,
	.menu--juliet .menu-item::after {
		-webkit-transform: scale3d(0, 1, 1);
		transform: scale3d(0, 1, 1);
		-webkit-transform-origin: left left;
		transform-origin: left left;
		-webkit-transition: transform 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
		transition: transform 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
	}

	.menu--juliet .menu-item.active::before,
	.menu--juliet .menu-item.active::after {
	   	-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
		-webkit-transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
		transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
	}

	.menu--juliet .menu-item:hover::before,
	.menu--juliet .menu-item:hover::after {
	   	-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
		-webkit-transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
		transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
	}

	.menu--juliet .menu-item::before {
		top: 95%;
		margin-left: -9px;
		border-width: 10px;
		border-top-color: #7c7c7c;
	}

	.menu--juliet .menu-item.active::before {
		margin-left: -9px;
		border-width: 10px;
		border-top-color: #252525;
	}

	.menu--juliet .menu-item::after,
	.menu--juliet .menu-item.active::after {
		top: 94%;
		margin-left: -5px;
		border-width: 6px;
		border-top-color: #fff;
	}

	.menu--juliet a {
		position: relative;
		line-height: 2.4;
		margin: 0 1rem;
		padding-right: 0;
		padding-left: 0;
		color: #7c7c7c;
		-webkit-transition: color 0.4s;
		transition: color 0.4s;
	}

	.menu--juliet .menu-item.active a,
	.menu--juliet .menu-item.active a:hover,
	.menu--juliet .menu-item.active a:focus {
		color: #252525;
	}

	.menu--juliet .menu-item.active a::after,
	.menu--juliet .menu-item.active a::before {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	.menu--juliet .menu-item.active a::before {
		-webkit-transition-delay: 0s;
		transition-delay: 0s;
	}

	.menu--juliet a:hover,
	.menu--juliet a:focus {
		color: #7c7c7c;
	}

	.menu--juliet .menu-item:hover a::before,
	.menu--juliet .menu-item:focus a::before {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
		-webkit-transition-delay: 0s;
		transition-delay: 0s;
	}

	.menu--juliet a::before,
	.menu--juliet a::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 3px;
		-webkit-transform: scale3d(0, 1, 1);
		transform: scale3d(0, 1, 1);
		-webkit-transform-origin: center left;
		transform-origin: center left;
		-webkit-transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
		transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
	}

	.menu--juliet a::before {
		background: #7c7c7c;
		-webkit-transition-delay: 0.4s;
		transition-delay: 0.4s;
	}

	.menu--juliet a::after {
		background: #252525;
	}

	/* End: Juliet Styles */

} /* End temporary tablet upwards wrap of mobile code */

.brand-wrap, .brand-wrap-mobile {
	font-family: 'Playfair Display';
	position: relative;
}

.menu-item.brand-wrap a, .brand-wrap-mobile a {
    font-family: 'Playfair Display';
    font-weight: 900;
    line-height: .75;
    position: relative;
}

.brand-wrap-mobile {
	padding-top: 1rem;
	text-align: center;
}

.brand-wrap-mobile:before {
	display: table;
    content: " ";
}

.brand-wrap-mobile a {
	color: #252525;
	font-size: 2.25rem;
}

/* Menu hover fix for logo */

.menu--juliet .menu-item.brand-wrap a:focus, .menu--juliet .menu-item.brand-wrap a:hover {
	color: #252525;
}

.menu--juliet .menu-item.brand-wrap:before {
	border-top-color: #252525;
}

.menu--juliet .menu-item.brand-wrap a:before {
	background: #252525;
}

.blog-description {
	color: #fff;
	font-family: 'Playfair Display';
	margin-top: 2.5rem;
	padding: 1rem 1rem 1rem 0;
}

/* Mobile fix for menu items */

@media (min-width: 768px) {
	.blog-description {
		color: #252525;
		margin-top: 0;
		text-align: center;
	}
}

@media (max-width: 767px) {
	.menu--juliet li:first-child a {
		margin-left: 0;
	}
	.menu--juliet li:last-child a {
		margin-right: 0;
	}
}

@media (min-width: 992px) {
	.nav {
		padding-left: 3.685rem;
	}
	.menu-item a {
		font-size: .875rem;
	}
	.menu-item.brand-wrap a {
    	font-size: 2.5rem;
    	line-height: .825;
    }
}

@media (min-width: 1200px) {
	.nav /* was called .menu-primary-navigation until I installef Soil plugin */ {
		/* Horrible cheaty fix to get logo to center - investigate if I can do this better! */
	    /* Navbar Fix is Right Two Items ( 309.75px ) - Left Two Items ( 244.97px ) = 64.78px margin-right? */
	    padding-left: 4.04875rem;
	}
	.menu-item a {
		font-size: 1rem;
	}
	.navbar .menu-item.brand-wrap a {
	    font-size: 3.175rem;
	    line-height: .75;
	    margin-left: 3rem;
    	margin-right: 3rem;
	}
}


/**********

***** Post Styling/ Markup *****

**********/

.post-tags {
    font-family: 'Playfair Display';
    font-size: 1.125rem;
    font-style: italic;
    font-weight: 400;
}

.post-tags a {
	color: #252525;
}

/**********

***** Front Page *****

**********/

/***** Front Page Section > Hero/ Carousel Slider *****/

.post-slider {
	padding-left: 0;
	padding-right: 0;
}

.hero-slider figure {
	margin: 0;
    position: relative;
    /* */
	overflow: hidden;
}

.hero-slider figure a {
	color: #fff;
}

.hero-slider figure img {
    max-width: 100%;
    display: block;
    position: relative;
    /* */
    -webkit-transition: -webkit-transform 0.4s;
    -moz-transition: -moz-transform 0.4s;
    transition: transform 0.4s;
}

.hero-slider .post-tags {
	padding: 0 1.25rem 1.25rem;
}

.hero-slider .post-title {
	line-height: 1.357142857142857;
	margin-bottom: 0;
	padding: 0 1.25rem;
}

/*.no-touch */.hero-slider figure:hover img, .hero-slider figure.cs-hover img {
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
}

/* https://tympanus.net/Tutorials/CaptionHoverEffects/index3.html */
.hero-slider figure figcaption {
	position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
    background: #252525;
    color: #EA3701;
	/* */
    /* height: 100px; */
    width: 100%;
    top: auto;
    bottom: 0;
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
    -moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
    transition: transform 0.4s, opacity 0.1s 0.3s;
}

/*.no-touch */ .hero-slider figure:hover figcaption, .hero-slider figure.cs-hover figcaption {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: -webkit-transform 0.4s, opacity 0.1s;
    -moz-transition: -moz-transform 0.4s, opacity 0.1s;
    transition: transform 0.4s, opacity 0.1s;
}

/***** Front Page Section > Recent Posts *****/

.post-image {
    overflow: hidden;
}

.post-image img {
	height: 100%;
	object-fit: cover;
	width: 100%;
}

.recent-posts .post-title, .recent-posts .post-tags {
	margin-bottom: 1.875rem;
}

.recent-posts .post-title {
	line-height: 1.392857142857143;
}

.recent-posts .post-tags {
	color: #ea3701;	
}

.recent-posts .btn-primary {
	margin-top: 0.875rem;
}

.post-title a {
	color: #252525;
}

.recent-post-row .btn-primary {
	display: table;
	margin-top: 2.5rem;
}

@media (min-width: 768px) {
	.recent-posts .post-image {
	    height: 330px;
	}	
}

@media (min-width: 992px) {
	.recent-posts figure {
		margin-bottom: 2.08333rem;
	}
	.recent-posts .post-image {
	    height: 440px;
	}
}

@media (min-width: 1200px) {
	.recent-posts figure {
		margin-bottom: 3.125rem;
	}
	.recent-posts .post-image {
	    height: 540px;
	}
}

/***** Front Page Section > Dinner Ideas *****/

@media (max-width: 768px) {
	.dinner-ideas .post-image{
	margin-bottom: 1.5625rem;
	}
	.dinner-ideas article {
		margin-bottom: 1.5625rem;
	}

}

@media (min-width: 768px) {
	.dinner-ideas .post-image {
		height: 210px;
	}
}

@media (min-width: 992px) {
	.dinner-ideas .post-image {
		height: 283.33px;
	}
}

@media (min-width: 1200px) {
	.dinner-ideas .post-title {
		padding-top: 1.875rem;
	}
	.dinner-ideas .post-image {
		height: 350px;
	}
}

/***** Front Page Section > Quick & Easy *****/

.quick-easy figure {
	margin: 0;
    position: relative;
    /* */
	overflow: hidden;
}

.quick-easy figure a {
	color: #fff;
}

.quick-easy figure img {
    max-width: 100%;
    display: block;
    position: relative;
    /* */
    -webkit-transition: -webkit-transform 0.4s;
    -moz-transition: -moz-transform 0.4s;
    transition: transform 0.4s;
}

.quick-easy .post-tags {
	padding: 0 1.25rem 1.25rem;
}

.quick-easy .post-title {
	line-height: 1.357142857142857;
	margin-bottom: 0;
	padding: 0 1.25rem;
}

/*.no-touch */.quick-easy figure:hover img, .quick-easy figure.cs-hover img {
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
}

/* https://tympanus.net/Tutorials/CaptionHoverEffects/index3.html */
.quick-easy figure figcaption {
	position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
    background: #252525;
    color: #EA3701;
	/* */
    /* height: 100px; */
    width: 100%;
    top: auto;
    bottom: 0;
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
    -moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
    transition: transform 0.4s, opacity 0.1s 0.3s;
}

/*.no-touch */ .quick-easy figure:hover figcaption, .quick-easy figure.cs-hover figcaption {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: -webkit-transform 0.4s, opacity 0.1s;
    -moz-transition: -moz-transform 0.4s, opacity 0.1s;
    transition: transform 0.4s, opacity 0.1s;
}

@media (max-width: 768px) {
	.quick-easy article {
	    margin-bottom: 1.875rem;
	}
}

@media (min-width: 1200px) {
	.quick-easy article {
		margin-bottom: 1.875rem;
		min-height: 2.375rem;
	}
}

/***** Front Page Section > Featured Story *****/

.featured-story .post-tags, .featured-story .post-title {
    margin-bottom: 1.875rem;
}

.featured-story .post-tags {
	color: #ea3701;
}

.featured-story .btn-primary {
	margin-top: 0.875rem;
}

@media (min-width: 1200px) {
	.featured-story .post-image {
	    height: 540px;		
	}
}

/***** Front Page Section > Ingredients *****/

.ingredients-row {
	background: #F2F2F2;
	padding: 1.5rem 7.5px 0;
}

.ingredients-col {
	padding-left: 7.5px;
	padding-right: 7.5px;
}

.ingredients h4 {
	font-size: 1rem;
	margin: 0;
	padding: 1.25rem;
	text-align: center;
}

.ingredients h4 a {
	color: #252525;
}

/***** Front Page Section > Social Media *****/

.sm-list {
	background: #F2F2F2;
	padding: 1.5rem;
	text-align: center;
}

.sm-list ul {
	list-style: none;
	margin-bottom: 0;
	padding-left: 0;
}

.sm-list ul li {
	display: inline;
}

.sm-list ul li {
	margin-right: 3.125rem;
}

.sm-list ul li:last-child {
	margin-right: 0;
}

.sm-list ul li a img {
	height: auto;
	width: 3.125rem;
}

/**********

***** Archive, Single Posts, Search Pages *****

**********/

.archive-image {
	margin-bottom: 3.125rem;
}

.archive-post .post-tags, .archive-post .post-title, .archive-post .post-ingredients {
    margin-bottom: 1.875rem;
}

.archive-post .post-title {
    line-height: 1.392857142857143;
}

/**********

***** Single Posts *****

**********/

.single-post .entry-title, .single-post time.updated {
	text-align: center;
}

.single-post time.updated {
    margin: 1.5rem auto 0;
    padding-top: .5rem;
}

time.updated {
	display: table;
    border-top: 5px solid;
}

.img-feature {
	padding: 1.5rem 0;
}

/**********

***** Sidebar *****

**********/

.sidebar section {
	margin-bottom: 0;
}

section.widget h3 {
	margin-bottom: 1.75rem;
    padding-bottom: 1.25rem;
	position: relative;
}

section.widget h3:after {
    content: "";
    position: absolute;
    width: 5rem;
    height: .5rem;
    bottom: 0;
    left: 0;
    border-bottom: .5rem solid #252525;
}

.sidebar-img, section.widget_search, section.widget_wpb_widget {
	margin-bottom: 1.875rem;
}

.sidebar .search-form .search-field {
	border-radius: 0;
}

.sidebar .search-form .search-submit {
	color: #fff;
    background-color: #252525;
    border-color: #252525;
}

.sm-list-sidebar {
	background: #f2f2f2;
    padding: 1.5rem;
}

.sm-list-sidebar ul {
	list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    overflow: hidden;
}

.sm-list-sidebar ul li {
    display: inline-block;
    float: left;
    margin-right: 1.25rem;
}

.sm-list-sidebar ul li h4 {
	margin-bottom: 0;
}

.sm-list-sidebar ul li a img {
    height: auto;
    width: 1.5rem;
}

/**********

***** Footer *****

**********/

.content-info {
	background: #252525;
	color: #fff;
	padding: 3.125rem 0;
}

.content-info section {
	margin-bottom: 0;
}

.content-info .widget_text {
	font-size: .75rem;
}

.content-info a {
	color: #fff;
}

.back-to-top {
    position: absolute;
    bottom: 0;
    right: 15px;
}